.checkboxVazio {
  display: block;
  width: 24px;
  height: 24px;
  border: solid #40f1ee;
  border-radius: 8px;
}
.checkboxCheio img {
  width: 15px;
}
.checkboxCheio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: #40f1ee;
  border-radius: 8px;
}
