@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "BMWGroupTNProTT-Light";
  /* Nome que você deseja dar à fonte */
  src: url("./assets/fontes/BMWGroupTNProTT-Light.woff") format("truetype");
  /* Caminho para o arquivo de fonte */
}

.arial {
  font-family: Arial, Helvetica, sans-serif !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  /* box-sizing:border-box; */
}

.tituloPagina {
  font-size: 30px;
  margin: 15px 0;
  color: #555;
}

.tituloPagina::after {
  content: "";
  display: block;
  width: 500px;
  height: 5px;
  /* background: linear-gradient(
    90deg,
    rgba(0, 213, 255, 0.37) 0%,
    rgba(255, 255, 255, 1) 100%
  ); */
  margin: -10px 0 0 0;
}

.subTituloPagina {
  font-size: 20px;
  font-weight: 500;
  margin-top: 30px;
  color: #555;
}

.subTituloPagina::after {
  content: "";
  display: block;
  width: 100%;
  height: 5px;
  /* background: rgb(206, 214, 218); */
  margin: -5px 0 0 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pointer {
  cursor: pointer;
}

.row {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none !important;
  list-style: none !important;
}

select {
  font-size: 12px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

label {
  font-size: 12px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: -7px;
  text-overflow: ellipsis;
}

li,
ul {
  text-decoration: none !important;
  list-style-type: none !important;
}

textarea:focus,
input:focus,
select:focus {
  box-shadow: none !important;
}
.inputPrimary:disabled,
.inputPrimary:disabled:focus {
  background-color: #5757576b !important;
  color: #000000 !important;
}

.inputPrimary,
.inputPrimary:hover,
.inputPrimary:active,
.inputPrimary:focus {
  font-size: 18px !important;

  text-align: left;
  background-color: #03597100;
  border-radius: 10px;
  border-radius: 10px;
  padding: 5px;
  border: solid 1px #b6b6b6;
  box-shadow: none !important;
  width: 100%;
  min-height: 50px;
  outline: none;
}
.btn-secondary:disabled {
  background: #888888;
  opacity: 1 !important;
  border: none;
  color: #ffffff;
}

.btn-secondary {
  background: #6f6f6f;
  min-height: 50px;
  border: 0;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  border: none;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background: #919191;
  border: none;
}

* {
  /* background-color: rgba(0, 0, 255, 0.144) !important; */
}

input[type="password"] {
  padding-right: 50px !important;
}

.avisoCampo {
  color: #e90c0c;
  font-weight: bold;
  font-size: 12px;
}

.configModal {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border: 1px solid #000;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.801);
  padding: 32px;
}

.tresPontinhos {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-control:focus {
  box-shadow: transparent;
}

.btn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn:disabled {
  background-color: #888 !important;
  border: solid 1px #888 !important;
}

.btn-primary {
  background-color: #db3db1;
  border-radius: 25px;
  border: 0;
  font-weight: 500;
  font-size: 15px;
  border: solid 1px #db3db1;
  width: 100%;
  height: 50px;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: #db3db1 !important;
  filter: grayscale(0.5);
  border: solid 1px #db3db1;
}
.btn-danger {
  background-color: #710303;
  border-radius: 25px;
  border: 0;
  font-weight: 500;
  font-size: 15px;
  border: solid 1px #710303;
  width: 100%;
  height: 50px;
}

.btn-danger:hover,
.btn-danger:active {
  background-color: #a10f0f !important;
  filter: grayscale(0.5);
  border: solid 1px #a10f0f;
}
.btn-secondary {
  background-color: #6f6f6f;
  border-radius: 25px;
  border: 0;
  font-weight: 500;
  font-size: 15px;
  border: solid 1px #6f6f6f;
  width: 100%;
  height: 50px;
}

.btn-secondary:hover,
.btn-secondary:active {
  background-color: #a8a8a8 !important;
  filter: grayscale(0.5);
  border: solid 1px #a8a8a8;
}

.btn-warning {
  border-radius: 10px;
  border: 0;
  color: #0c2a4e;
  font-weight: 500;
  font-size: 15px;
  padding: 14px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  /* margin-top: 5px; */
  /* position: initial !important; */
}

.swiper-pagination-bullet {
  opacity: 1 !important;
  background: #fafafa !important;
  background-color: #fafafa88 !important;
  width: 10px !important;
  height: 10px !important;
  margin: 0 10px !important;
}

.swiper-pagination-bullet-active {
  background: #db3db1 !important;
  scale: 1.3;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff !important;
  font-size: 20px !important;
}
