.barras {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 5px;
  margin: 5px 0 0 0;
  border-radius: 10px;
}
.bar {
  width: 23%;
  height: 8px;
  background-color: #e4e4e4;
  border-radius: 10px;
}

.barraVazia {
  height: 100%;
  width: 0%;
  border-radius: 10px;
}
.barraCheia {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.barraEsvaziando {
  height: 100%;
  width: 0%;
  border-radius: 10px;
  animation: vazio 1s backwards;
}
.barraEnchendo {
  height: 100%;
  animation: cheio 1s;
  border-radius: 10px;
}
.bar1 {
  background-color: rgb(255, 0, 0);
  transition: all 1s;
  border-radius: 10px;
}
.bar2 {
  background-color: rgb(255, 127, 0);
  transition: all 1s;
  border-radius: 10px;
}
.bar3 {
  background-color: rgb(230, 200, 0);
  transition: all 1s;
  border-radius: 10px;
}
.bar4 {
  background-color: rgb(0, 255, 0);
  transition: all 1s;
  border-radius: 10px;
}

@keyframes cheio {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes vazio {
  100% {
    width: 0%;
  }
  0% {
    width: 100%;
  }
}
.textoSenha {
  font-size: 12px;
  margin: 0;
  padding: 0;
}
