.faqDeslogado {
  padding: 30px;
  color: rgb(0, 0, 0);
}
.faqDeslogado p {
  padding: 10px 0;
}
.faqDeslogado .c6 {
  color: rgb(255, 235, 0);
  font-weight: 700;
}

.faqDeslogado table {
  display: flex;
  justify-content: center;
  width: 100%;
  width: 80%;
  cursor: default;
  color: rgb(0, 0, 0);
}
.faqDeslogado td {
  background-color: transparent !important;
  border: solid 1px black !important;
  color: rgb(0, 0, 0) !important;
}
.faqDeslogado table tr:nth-child(even) {
  background-color: #cf151500;
}

.faqDeslogado table tr:hover {
  background-color: rgba(255, 255, 255, 0.233);
}
.tituloFaq {
  background-color: rgb(245, 245, 245);
  margin-top: 10px;
}
.accordion-button:focus {
  box-shadow: none;
}
.tituloFaq:not(.collapsed) {
  color: initial;
  background-color: rgb(245, 245, 245);
}
.tituloPerguntas { 
  margin: 30px 0;
  font: normal normal 300 40px/49px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
