.tituloCadastro {
    text-align: left;
    font: normal normal 300 22px/29px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    border-bottom: 5px #ededed solid;
    padding-left: 25px;
    margin: 60px 0 10px 0;
  }
  .iconeOlho{
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
    font-size: 30px !important;
    color: #aaa;
  }